import { tns } from 'tiny-slider/dist/tiny-slider';
import 'tiny-slider/dist/tiny-slider.css';

export class HomePage {

    constructor() {
        $(() => {
            const slider = tns({
                container: '.home-slider',
                controlsText: [
                    '<span class="sr-only">Previous Slide</span>&lsaquo;', '<span class="sr-only">Next Slide</span>&rsaquo;'
                ]
            });

            // if default slide has a link, show the cursor as a pointer
            let defaultInfo = slider.getInfo();
            let defaultTarget = defaultInfo.slideItems[defaultInfo.index];
            let defaultLinkObj = $(defaultTarget).find("a");
            if (defaultLinkObj.length > 0) {
                $("#slider").addClass("show-pointer");
            }

            $('#slider').on('click', () => {
                // Get Information on Current Slide
                let info = slider.getInfo();
                let target = info.slideItems[info.index];

                // See if Current Slide has a Link
                let linkObj = $(target).find("a");

                // If navigating to new slide
                if (info.index != info.indexCached) {
                    // if new slide has a link, make the cursor a pointer
                    if (linkObj.length > 0) {
                        $("#slider").addClass("show-pointer");
                    }
                    // if the new slide does NOT have a link, remove the pointer
                    else {
                        $("#slider").removeClass("show-pointer");
                    }
                    return;
                }


                // If the current slide has a link, open it
                if (linkObj.length > 0) {
                    let targetLink = linkObj[0].href;
                    if (targetLink) {
                        window.open(targetLink, '_blank').focus();
                    }
                }
            })
        });
    }

}