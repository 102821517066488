import { plainToClass } from 'class-transformer';
import { Publication } from './publications.entities';

export class PublicationsPage {

    publications: Publication[];
    dataTable: any;

    constructor(data: any, lastUpdated: string) {
        $(() => {

            //console.log(data);

            this.publications = plainToClass(Publication, <Publication[]>data);
            this.publications.map((pub: Publication) => {
                pub.initialize();
                return pub;
            });

            //console.log(this.publications);

            this.initializeTable();
            this.setPublicationsFoundLabel(this.publications.length);

            $('#last-update-date').text(lastUpdated);
        });
    }

    initializeTable(): void {

        this.dataTable = $('#public-publications-table').DataTable({
            "dom": '<"top-controls"<"reset-section">Bf>rtip',
            autoWidth: false,
            info: false,
            paging: false,
            search: true,
            scrollX: false,
            orderCellsTop: true,
            language:
            {
                search: "Filter",
                buttons: {
                    copyTitle: 'Publications Data',
                    copyKeys: 'Use your keyboard or menu to select the copy command'
                }
            },
            columns: [
                { className: 'details-control', orderable: false, data: null, defaultContent: '', width: '24px' }, //0
                { data: "Title", className: "text-left pl-0" }, //1
                { data: "AuthorStringTruncated", className: "text-left font-size12", width: '20%' }, //2
                { data: "JournalAbbreviation", className: "text-left font-size12", width: '12%' }, //3
                { data: "PublishedYear", className: "text-left font-size12", width: '8%' }, //4
                { data: "Project", className: "text-left font-size12", width: '10%' }, //5
                { data: "AuthorStringFull", title: 'Authors', visible: false }, //6
                { data: "PublishedMonthNumber", visible: false }, //7
                { data: "Abstract", title: "Abstract", visible: false }, //8
                { data: "MeshTermsString", title: "Keywords", visible: false }, //9
                { data: "PublishedDateString", title: "PublicationDate", visible: false }, //10
                { data: "JournalTitle", title: "JournalTitle", visible: false }, //11
                { data: "DoiNumber", title: "DOI", visible: false }, //12
                { data: "CombinedHiddenFilter", visible: false }, //13
                { data: "JournalVolume", title: "JournalVolume", visible: false }, //14
                { data: "JournalPages", title: "JournalPages", visible: false }, //15
                { data: "PubMedId", title: "PubMedId", visible: false }, //16
                { data: "LayPersonSummary", title: "Summary", visible: false }, //17
            ],
            buttons: [
                {
                    extend: 'copy',
                    text: '<i class="fas fa-copy"></i>',
                    titleAttr: 'Copy',
                    charset: 'utf-8',
                    exportOptions: {
                        columns: [1, 11, 14, 15, 10, 6, 8, 9, 12, 16, 17]
                    },
                    title: ''
                }
                , {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                    exportOptions: {
                        columns: [1, 11, 14, 15, 10, 6, 8, 9, 12, 16, 17]
                    }
                },
            ],
            data: this.publications,
            order: [[4, 'desc'], [8, 'desc']],
            initComplete: (settings: any, json: any) => {
                $("#public-publications-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            }
        });

        let resetSectionDiv: string =
            "<div>" +
            "<button type=\"button\" class=\"btn btn-sm btn-outline-secondary font-size12\" onclick=\"page.resetFilters()\">Reset Filters</button>" +
            "</div>"
            ;
        $(".reset-section").empty().html(resetSectionDiv);
        $(".reset-section").css('display', 'inline-block');
        $(".reset-section").css('float', 'left');
        $(".reset-section").css('margin-top', '5px');

        //on 'td.details-control' removed
        $('#public-publications-table tbody').on('click', 'tr', (event) => {
            //var tr = $(event.currentTarget).closest('tr');
            var tr = $(event.currentTarget);
            var row = this.dataTable.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                // Open this row
                row.child(this.createDetailRow(row.data())).show();
                tr.addClass('shown');
            }
        });

        $('#public-publications-table').on('search.dt', (e, settings) => {
            this.setPublicationsFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });


        //setTimeout(() => { this.initializeFilters(); }, 500);
        this.initializeFilters();
    }

    initializeFilters(): void {

        for (let index = 2; index <= 5; ++index) { //skip details and title column

            let filterIndex: number = (index == 2) ? 6 : index; //hidden full author list

            let uniqueValues: string[] = [];
            let delimiter: string = ",";

            if (index == 2) {  //authors should use full author list not truncated list
                delimiter = ";";
            }

            this.dataTable.column(filterIndex).data().unique().map((data: string) => {
                //console.log(data);
                let parts: string[] = data.split(delimiter);
                parts.map((part: string) => {
                    part = part.trim();
                    if (part.length > 0 && !uniqueValues.includes(part)) {
                        uniqueValues.push(part);
                    }
                });
            });

            uniqueValues = uniqueValues.sort((a: string, b: string) => {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });

            let selectId: string = '#filter-dropdown-' + index;

            $(selectId).selectpicker({
                dropupAuto: false,
                selectedTextFormat: "count",

                countSelectedText: (numSelected, numTotal) => {
                    if (numSelected == numTotal) {
                        return "All";
                    }
                    else {
                        return numSelected + " Selected";
                    }
                }
            });

            uniqueValues.forEach((element: any) => {
                $(selectId).append(new Option(element, element, false, true));
            });
            $(selectId).selectpicker("refresh");


            $(selectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
                let values: string[] = <string[]>($(selectId).val());
                //console.log("changed", values);

                let search: string = "";
                if (values.length > 0) {
                    if (index == 2) { //more strict match
                        search = "(\\b" + values.join("\\b)|(\\b") + "\\b)";
                    }
                    else {
                        search = "^(" + values.join("|") + ")$";
                    }
                }
                else { search = "NOTHING-MATCHED-1234567890"; }

                //console.log(search);

                this.dataTable.column(filterIndex)
                    .search(search, true, false)
                    .draw();
            });

        }
    }

    createDetailRow(data: Publication): string {
        // `data` is the original data object for the row
        let detailRow: string = '<table class="detail-table" cellpadding="4" cellspacing="0" border="0" style="padding-left:0px;">';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12"><i>Title</i></td>' +
            '<td class="text-left">' + data.Title + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12"><i>Journal</i></td>' +
            '<td class="text-left">' + data.JournalTitle + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12"><i>Published</i></td>' +
            '<td class="text-left">' + data.PublishedDateString + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12"><i>Authors</i></td>' +
            '<td class="text-left">' + data.AuthorStringFull + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12"><i>Summary</i></td>' +
            '<td class="text-left">' + data.LayPersonSummary + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12"><i>Abstract</i></td>' +
            '<td class="text-left">' + data.Abstract + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row">' +
            '<td class="text-left font-size12"><i>Keywords</i></td>' +
            '<td class="text-left">' + data.MeshTermsString + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row">' +
            '<td class="text-left font-size12"><i>DOI</i></td>';

        if (data.Source == "pubmed" && data.DoiNumber != "Not Available") {
            detailRow += '<td class="text-left"><a href="' + data.Link + '" target="_blank"><span>' + data.DoiNumber + '<i class="fa fa-external-link-alt link-icon" aria-hidden="true"</i></span></a></td>';
        }
        else {
            detailRow += '<td class="text-left">' + data.DoiNumber + '</td>';
        }
        detailRow += '</tr>';

        if (data.Source == "pubmed") {
            detailRow += '<tr class="detail-row">' +
                '<td class="text-left font-size12"><i>PubMedId</i></td>' +
                '<td class="text-left">' + data.PubMedId + '</td>' +
                '</tr>';
            detailRow += '<tr class="detail-row">' +
                '<td></td>' +
                '<td class="text-right"><a href="' + data.Link + '" target="_blank" class="butn small"><span>PubMed Record <i class="fa fa-external-link-alt link-icon" aria-hidden="true"</i></span></a></td>' +
                '</tr>';
        }
        else if (data.Source == "local") {
            detailRow += '<tr class="detail-row">' +
                '<td></td>' +
                '<td class="text-right"><a href="' + data.Link + '" target="_blank" class="butn small"><span>View Publication</span></a></td>' +
                '</tr>';
        }


        detailRow += '</table>';

        return detailRow;
    }

    resetFilters(): void {
        let filterIndex: number;
        for (let index = 4; index >= 2; --index) { //skip details and title column

            $('#filter-dropdown-' + index).selectpicker('selectAll');
            $('#filter-dropdown-' + index).selectpicker("refresh");

            filterIndex = (index == 2) ? 6 : index; //full author list
            this.dataTable.column(filterIndex).search('').draw();
        }
        this.dataTable.search('').draw();
    }

    setPublicationsFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#publications-found-count').text("No Publications Found"); break;
            case 1: $('#publications-found-count').text("1 Publication Found"); break;
            default: $('#publications-found-count').text(count + " Publications Found");
        }
    }
}