import L, { Marker, Point } from "leaflet";
import { basemapLayer, featureLayer } from 'esri-leaflet';
import { MapLocation } from "./network.entities";

export class NetworkMap {

    map: L.Map;
    basemapDetailedLayer: L.Layer;
    basemapGrayscaleLayer: L.Layer;
    markersLayerLocations: L.LayerGroup;
    markerRenderer: L.Renderer;
    clusterLayer: any;

    constructor(mapLocations: MapLocation[]) {

        $('#network-map').hide();
        $('#loading-indicator').show();

        this.initializeMap();
        this.setMarkers(mapLocations);
    }

    initializeMap(): void {
        $("#network-map").empty();

        this.map = L.map('network-map', {
            zoomSnap: 0.1,
            worldCopyJump: true
        });

        this.basemapDetailedLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(this.map);
        this.basemapGrayscaleLayer = basemapLayer('Gray');

        this.markersLayerLocations = L.layerGroup().addTo(this.map);


        this.setupMarkerCluster();

        this.clusterLayer = L.markerClusterGroup({
            //maxClusterRadius: 5,
            //disableClusteringAtZoom: this.map.options.maxZoom + 1
            zoomToBoundsOnClick: false,
            iconCreateFunction: function (cluster) {
                return new L.DivIcon({ html: cluster.getChildCount() + '', className: 'site-marker-cluster', iconSize: L.point(18, 18) });
            },
            spiderfyShapePositions: function (count: number, centerPt: { y: number; x: number; }) {
                var distanceFromCenter = 25,
                    markerDistance = 25,
                    lineLength = markerDistance * (count - 1),
                    lineStart = centerPt.y - lineLength / 2,
                    res = [],
                    i;

                res.length = count;

                for (i = count - 1; i >= 0; i--) {
                    res[i] = new Point(centerPt.x - distanceFromCenter, lineStart + markerDistance * i);
                }

                return res;
            }
        } as any); //casted to 'any' as shapePositions not part of type

        this.clusterLayer.on('clusterclick', function (a) {
            //console.log(a);
            a.layer.spiderfy();
        });

        this.map.setView([38, -95], 4.5); //usa

        this.markerRenderer = L.canvas({ padding: 0.5 });

        L.control.layers({
            "Detailed": this.basemapDetailedLayer,
            "Grayscale": this.basemapGrayscaleLayer
        }, {}).addTo(this.map);

        $('#loading-indicator').hide();
        $('#network-map').show();
        this.map.invalidateSize();

        this.map.on("baselayerchange", (e: any) => this.setAttribution(e.name));
        this.setAttribution("Grayscale");
    }

    setAttribution(basemap: string): void {

        if (basemap == "Grayscale") {
            $('.leaflet-control-attribution').html('Powered by <a href="https://www.esri.com">Esri</a>');
        }
        else if (basemap == "Detailed") {
            $('.leaflet-control-attribution').html('Powered by <a href="https://www.openstreetmap.org/">OpenStreetMap</a>');
        }
    }

    setMarkers(mapLocations: MapLocation[]) {

        if (this.map.hasLayer(this.markersLayerLocations)) {
            this.markersLayerLocations.clearLayers();
        }
        if (this.map.hasLayer(this.clusterLayer)) {
            this.clusterLayer.clearLayers();
        }

        mapLocations.forEach((location: MapLocation) => {

            if (location.type == "Other") {
                (<any>L).shapeMarker([location.coords[0], location.coords[1]], {
                    shape: 'triangle',
                    color: location.color,
                    radius: 6,
                    weight: 1,
                    fillOpacity: 1.0
                }).addTo(this.markersLayerLocations).bindPopup(this.createPopupContent(location));
            }
            else if (location.type == "Site") {

                //if (!location.address2.includes("UT")) {
                    L.circleMarker([location.coords[0], location.coords[1]], {
                        renderer: this.markerRenderer,
                        color: 'black',
                        fillColor: location.color,
                        radius: 6,
                        fillOpacity: 1.0,
                        weight: 1
                    }).addTo(this.markersLayerLocations).bindPopup(this.createPopupContent(location));
                //}
                //else {
                    //this.clusterLayer.addLayer(L.circleMarker([location.coords[0], location.coords[1]], {
                    //    renderer: this.markerRenderer,
                    //    color: 'black',
                    //    fillColor: location.color,
                    //    radius: 6,
                    //    fillOpacity: 1.0,
                    //    weight: 1
                    //}).bindPopup(this.createPopupContent(location)));
                //}
            }
        });

        //this.map.addLayer(this.clusterLayer);
    }

    createPopupContent(location: MapLocation): string {

        let popupContent: string = "<div class='map-popup'>";
        if (location.type == "Site") {
            popupContent += "<div class='location font-italic'>Study Site</div>";
            popupContent += "<div class='title mt-1'> " + location.name + " </div>";
            popupContent += "<hr class='my-1'>";
            popupContent += "<div class='location my-2'> " + location.address1 + "<br />" + location.address2 + " </div>";
            popupContent += "<hr class='my-1'>";
            popupContent += "<div class='location my-2'> " + location.contactName + "<br /><a href='mailto:'" + location.contactEmail + "'>" + location.contactEmail + "</a></div>";
        }
        else {
            popupContent += "<div class='location font-italic'>" + location.role + "</div>";
            popupContent += "<div class='title mt-1'> " + location.name + " </div>";
            popupContent += "<hr class='my-1'>";
            popupContent += "<div class='location my-2'> " + location.address1 + "<br />" + location.address2 + " </div>";
        }

        popupContent += "<div class='location'> ";
        popupContent += "</div>";
        //popupContent += "<div class='learn-more'><a href=" + "/research-centers/" + rcenter.detailsLink + " target='_blank'> Learn More </a></div>";

        popupContent += "</div>";
        return popupContent;
    }

    //hopefully will be merged into official marker cluster release (if that ever happens again)
    setupMarkerCluster(): void {
        L.MarkerCluster.include({
            spiderfy: function () {
                if (this._group._spiderfied === this || this._group._inZoomAnimation) {
                    return;
                }

                var childMarkers = this.getAllChildMarkers(null, true),
                    group = this._group,
                    map = group._map,
                    center = map.latLngToLayerPoint(this._latlng),
                    positions;

                this._group._unspiderfy();
                this._group._spiderfied = this;

                //TODO Maybe: childMarkers order by distance to center

                if (this._group.options.spiderfyShapePositions) {
                    positions = this._group.options.spiderfyShapePositions(childMarkers.length, center);
                } else if (childMarkers.length >= this._circleSpiralSwitchover) {
                    positions = this._generatePointsSpiral(childMarkers.length, center);
                } else {
                    center.y += 10; // Otherwise circles look wrong => hack for standard blue icon, renders differently for other icons.
                    positions = this._generatePointsCircle(childMarkers.length, center);
                }

                this._animationSpiderfy(childMarkers, positions);
            }
        });
    }

}