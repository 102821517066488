import { NetworkMap } from "./network.map";
import { MapLocation } from "./network.entities";


export class NetworkPage {

    map: NetworkMap;
    locations: MapLocation[];

    constructor() {
        $(() => {

            this.loadLocations();

            //console.log(this.researchCenters);

            this.map = new NetworkMap(this.locations);

        });
    }

    loadLocations(): void {
        this.locations = [];
        this.locations.push(new MapLocation("Case Western Reserve University", "Site", [41.462248215210984, -81.69832780507465], "#de3034", "2500 MetroHealth Dr.", "Cleveland, OH 44109", "", "Brittany DeSantis", "bdesantis@metrohealth.org"));
        this.locations.push(new MapLocation("Christiana Care", "Site", [39.686110547785816, -75.6716944699156], "#de3034", "4755 Ogletown-Stanton Road", "Newark, DE 19718", "", "Jennifer Nava", "numom2bhhs@christianacare.org"));
        this.locations.push(new MapLocation("Columbia University", "Site", [40.84099906181419, -73.94109935423889], "#de3034", "622 West 168th Street, Suite 12-15", "New York, NY 10032", "", "Rupa Ravi", "rr3310@cumc.columbia.edu"));
        this.locations.push(new MapLocation("Indiana University", "Site", [39.781482931039854, -86.16515530943937], "#de3034", "550 University Blvd., Suite 5031", "Indianapolis, IN 46202", "", "Ligia Vasquez-Huot", "numomhrt@iu.edu"));
        this.locations.push(new MapLocation("Intermountain Medical Center", "Site", [40.65920411877394, -111.8917403513746], "#de3034", "5121 South Cottonwood Street", "Murray, UT 84107", "", "Michael Nunley", "michael.nunley@imail.org"));
        this.locations.push(new MapLocation("Magee Women’s Hospital", "Site", [40.43688429974889, -79.96083688956806], "#de3034", "300 Halket Street, Room 2120", "Pittsburgh, PA 15213", "", "Samantha Bryan", "NuMom2Be@upmc.edu"));
        this.locations.push(new MapLocation("Northwestern University", "Site", [41.893837358434446, -87.62238612922845], "#de3034", "633 N. St. Clair St., Suite 1800", "Chicago, IL 60611", "", "Elizabeth Rangel", "elizabeth.rangel@northwestern.edu"));
        this.locations.push(new MapLocation("University of California Irvine", "Site", [33.78711216050613, -117.88834355941243], "#de3034", "101 The City Drive South", "Orange, CA, 92868", "", "Linh Huỳnh", "plhuynh@hs.uci.edu"));
        this.locations.push(new MapLocation("University of Pennsylvania", "Site", [39.950164078260826, -75.19395288211322], "#de3034", "3400 Spruce St.", "Philadelphia, PA 19104", "", "M Corinne Steele", "numom2bstudy@pennmedicine.upenn.edu"));
        this.locations.push(new MapLocation("University of Utah Hospital", "Site", [40.764728, -111.826883], "#de3034", "295 S. Chipeta Way, Fl 3", "Salt Lake City, UT 84108", "", "Kelly Christensen", "kelly.christensen@hsc.utah.edu"));
        //this.locations.push(new MapLocation("LDS Hospital", "Site", [40.77973239820992, -111.87930201871244], "#de3034", "8th Avenue C Street", "Salt Lake City, UT 84143", "", "Nic Unsworth", "Nic.Unsworth@imail.org"));
        this.locations.push(new MapLocation("LDS Hospital", "Site", [40.77973239820992, -112.5], "#de3034", "8th Avenue C Street", "Salt Lake City, UT 84143", "", "Michael Nunley", "michael.nunley@imail.org"));
        this.locations.push(new MapLocation("McKay-Dee Hospital", "Site", [41.18469514639228, -111.95505178968014], "#de3034", "4401 Harrison Blvd", "Ogden, UT 84403", "", "Michael Nunley", "michael.nunley@imail.org"));
        this.locations.push(new MapLocation("Utah Valley Hospital", "Site", [40.248236792616346, -111.66540035390575], "#de3034", "1034 N 500 W", "Provo, UT 84604", "", "Michael Nunley", "michael.nunley@imail.org"));

        this.locations.push(new MapLocation("RTI International", "Other", [35.90529577200652, -78.86489931532732], "#4a393f", "3040 E Cornwallis Rd", "Durham, NC 27709", "Data Coordinating and Analysis Center", "", ""));
        this.locations.push(new MapLocation("Cedars-Sinai Medical Center", "Other", [34.07583871904912, 118.38007794824972], "#4a393f", "8700 Beverly Blvd.", "Los Angeles, CA 90048", "Biospecimen Repository", "", ""));
        this.locations.push(new MapLocation("Brown University", "Other", [41.811082711519525, -71.41264306704696], "#4a393f", "101 Dudley Street", "Providence, RI 02905", "Consultant Site", "", ""));
        this.locations.push(new MapLocation("The Ohio State University", "Other", [39.99946357344897, -83.03354695163455], "#4a393f", "1960 Kenny Road", "Columbus, OH 43210", "Collaborating Site", "", ""));
        this.locations.push(new MapLocation("Eastern Virginia Medical School", "Other", [36.860961, -76.300703], "#4a393f", "700W. W Olney Rd", "Norfolk, VA 23507", "Multiple PI Site", "", ""));
        this.locations.push(new MapLocation("Emory University", "Other", [33.789909, -84.325457], "#4a393f", "201 Dowman Drive", "Atlanta, GA 30322", "Collaborating Site", "", ""));
        this.locations.push(new MapLocation("Johns Hopkins University", "Other", [39.327888, -76.615348], "#4a393f", "101 E 33rd St", "Baltimore, MD 21218", "Collaborating Site", "", ""));
        this.locations.push(new MapLocation("Massachusetts General Hospital", "Other", [42.362709, -71.069257], "#4a393f", "55 Fruit St", "Boston, MA 02114", "Collaborating Site", "", ""));
    }
}