import WOW from 'wowjs';

export class BrainPage {

    constructor() {
        $(() => {
            //console.log('Hello, World!');
            new WOW.WOW({
                live: false
            }).init();
            //accessible smooth scroll
            $('#brainNav .nav-link').click(function (e) {
                e.preventDefault();
                const target = $(this).attr('href');
                const $target = $(target);
                $('html, body').animate({ scrollTop: $target.offset().top }, 1000, function () {
                    location.hash = target;
                    $target.focus();
                    if ($target.is(':focus')) {
                        return false;
                    } else {
                        $target.attr('tabindex', '-1');
                        $target.focus();
                    }
                });//end animate
            });//end click nav link
        });
    }

}