
// SCRIPTS

import { HomePage } from './home/home.page'; export { HomePage };
import { BrainPage } from './subsites/brain.page'; export { BrainPage };
import { NetworkPage } from './network/scripts/network.page'; export { NetworkPage };
import { PublicationsPage } from './publications/scripts/publications.page'; export { PublicationsPage };
import { ProposedManuscriptsPage } from './publications/proposed-manuscripts/scripts/proposed-manuscripts.page'; export { ProposedManuscriptsPage }

// STYLES
import './home/home.scss';
import './about/about.scss';
import './network/network.scss';
import './subsites/brain.scss';
import './publications/publications.scss';
