
export class ProposedManuscript {

    Authors: string;
    ManuscriptTitle: string;


    constructor() { }

    initialize(): void {


    }
}
