import { plainToClass } from 'class-transformer';
import { ProposedManuscript } from './proposed-manuscripts.entities';

export class ProposedManuscriptsPage {

    manuscripts: ProposedManuscript[];
    dataTable: any;

    constructor(data: any) {
        $(() => {

            this.manuscripts = plainToClass(ProposedManuscript, <ProposedManuscript[]>data);
            this.manuscripts.map((manuscript: ProposedManuscript) => {
                manuscript.initialize();
                return manuscript;
            });

            //console.log(this.publications);

            this.initializeTable();

        });
    }

    initializeTable(): void {

        this.dataTable = $('#proposed-manuscripts-table').DataTable({
            "dom": '<"top-controls"<"reset-section">Bf>rtip',
            autoWidth: false,
            info: false,
            paging: false,
            search: true,
            scrollX: false,
            orderCellsTop: true,
            language:
            {
                search: "Search",
                buttons: {
                    copyTitle: 'Manuscript Data',
                    copyKeys: 'Use your keyboard or menu to select the copy command'
                }
            },
            columns: [ 
                { data: "Authors", className: "text-left", width: "250px" }, //1
                { data: "ManuscriptTitle", className: "text-left" }, //2
            ],
            buttons: [
                {
                    extend: 'copy',
                    text: '<i class="fas fa-copy"></i>',
                    titleAttr: 'Copy',
                    charset: 'utf-8',
                    /*exportOptions: {
                        columns: [1, 11, 14, 15, 10, 6, 8, 9, 12, 16, 17]
                    },*/
                    title: ''
                }
                , {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                    /*exportOptions: {
                        columns: [1, 11, 14, 15, 10, 6, 8, 9, 12, 16, 17]
                    }*/
                },
            ],
            data: this.manuscripts,
            initComplete: (settings: any, json: any) => {
                $("#proposed-manuscripts-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            }
        });

        $('#proposed-manuscripts-table').on('search.dt', (e, settings) => {
            this.setManuscriptsFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });

    }

    
    setManuscriptsFoundLabel(count: number): void {

        /*switch (count) {
            case 0: $('#publications-found-count').text("No Publications Found"); break;
            case 1: $('#publications-found-count').text("1 Publication Found"); break;
            default: $('#publications-found-count').text(count + " Publications Found");
        }*/
    }
}